import { MenuDivider } from '@app/common/menu-divider'
import {
  BookmarkIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  HashIcon,
  ResetIcon,
} from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu, { menuClasses } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import classNames from 'classnames'
import { FunctionComponent, useRef, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import SearchInput from '../../common/search/search-input'
import VirtualTable, { VirtualTableProps } from '../../common/virtual-table/virtual-table'
import AddAssetsDialog from '../add-assets-dialog'
import BookmarksListCreateDialog from '../bookmarks-list/bookmarks-list-create/bookmarks-list-create-dialog'
import useBenchmarkingColumns, {
  SPOTLIGHT_ICON_BUTTON_CLASSNAME,
} from './benchmarking-table-columns'
import { BenchmarkingItem, BenchmarkingItemType } from './utils'

const columnVisibilityModel: ColumnVisibilityModel<BenchmarkingItem> = {
  description: 1120,
  ebitdaPctRevenue: 870,
  revenueEur: 710,
}

const styledVirtualTableClasses = generateUtilityClasses('StyledVirtualTable', [
  'missingData',
  'targetAsset',
])

const StyledVirtualTable = styled(
  VirtualTable as FunctionComponent<VirtualTableProps<BenchmarkingItem>>
)(({ theme }) => ({
  [`& .${styledVirtualTableClasses.missingData} .${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[500],
    [`&:not(.${SPOTLIGHT_ICON_BUTTON_CLASSNAME}) *`]: {
      color: theme.palette.grey[500],
    },
  },
  [`& .${styledVirtualTableClasses.targetAsset} .${tableCellClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))

const StyledRoot = styled(Card)(({ theme }) => ({
  maxHeight: 628,
  gap: theme.spacing(2),
}))

interface BenchmarkingTableProps
  extends Pick<VirtualTableProps<BenchmarkingItem>, 'onSort' | 'sort'> {
  assets: BenchmarkingItem[]
  deselectedAssetIds: number[]
  onSelectAssetId: (assetId: number, isChecked: boolean) => void
  missingDataAssetIds: number[]
  onAddAssets: (newAssetIds: number[]) => Promise<void>
  onReset: () => void
  hasChanges: boolean
  onSearch: (query: string | null) => void
  contextName: string
  showRankColumn: boolean
  spotLightAssetIds: number[]
  onClickSpotlightAssetId: (assetId: number) => void
  benchmarkSize: number
  onSetBenchmarkSize: (size: number) => void
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    width: 238,
  },
}))

export default function BenchmarkingTable({
  assets,
  deselectedAssetIds,
  onSelectAssetId,
  hasChanges,
  missingDataAssetIds,
  onAddAssets,
  onReset,
  sort,
  onSort,
  onSearch,
  contextName,
  showRankColumn,
  spotLightAssetIds,
  onClickSpotlightAssetId,
  benchmarkSize,
  onSetBenchmarkSize,
}: BenchmarkingTableProps) {
  const [openAddAssetsDialog, setOpenAddAssetsDialog] = useState(false)
  const [createBookmarkDialogOpen, setCreateBookmarkDialogOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const columns = useBenchmarkingColumns(
    deselectedAssetIds,
    missingDataAssetIds,
    spotLightAssetIds,
    onSelectAssetId,
    onClickSpotlightAssetId,
    showRankColumn
  )
  const visibleColumns = useVisibleColumns(ref, columns, columnVisibilityModel)

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [menuPage, setMenuPage] = useState<string>('start')

  const closeMenu = () => setMenuAnchorEl(null)

  return (
    <StyledRoot ref={ref}>
      <CardHeader
        actions={
          <Stack
            direction={'row'}
            gap={1}>
            <SearchInput
              initialValue={''}
              onChange={onSearch}
            />

            <Button
              color={'primary'}
              onClick={() => setOpenAddAssetsDialog(true)}
              variant={'text'}>
              Add company
            </Button>

            <Button
              color={'primary'}
              onClick={(event) => {
                setMenuPage('start')
                setMenuAnchorEl(event.currentTarget)
              }}
              variant={'text'}>
              More <ChevronDownIcon />
            </Button>

            <StyledMenu
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              onClose={closeMenu}
              open={Boolean(menuAnchorEl)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              {menuPage === 'start' && (
                <div>
                  <MenuItem onClick={() => setMenuPage('benchmark-size')}>
                    <ListItemIcon>
                      <HashIcon />
                    </ListItemIcon>
                    <ListItemText>Adjust benchmark size</ListItemText>
                    <ChevronRightIcon />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      closeMenu()
                      setCreateBookmarkDialogOpen(true)
                    }}>
                    <ListItemIcon>
                      <BookmarkIcon />
                    </ListItemIcon>
                    <ListItemText>Save to bookmark</ListItemText>
                  </MenuItem>
                  <MenuItem
                    disabled={!hasChanges}
                    onClick={() => {
                      closeMenu()
                      onReset()
                    }}>
                    <ListItemIcon>
                      <ResetIcon />
                    </ListItemIcon>
                    <ListItemText>Reset</ListItemText>
                  </MenuItem>
                </div>
              )}
              {menuPage === 'benchmark-size' && (
                <div>
                  <MenuItem onClick={() => setMenuPage('start')}>
                    <ListItemIcon>
                      <ChevronLeftIcon />
                    </ListItemIcon>
                    <ListItemText>Back to menu</ListItemText>
                  </MenuItem>
                  <MenuDivider />
                  {[10, 20, 30, 50, 75, 100, 150, 200, 300, 500].map((size) => (
                    <MenuItem
                      key={size}
                      onClick={() => {
                        closeMenu()
                        onSetBenchmarkSize(size)
                      }}
                      selected={benchmarkSize === size}>
                      <ListItemIcon />
                      <ListItemText>{size}</ListItemText>
                    </MenuItem>
                  ))}
                </div>
              )}
            </StyledMenu>
          </Stack>
        }
        title={'Benchmarking input'}
      />

      <StyledVirtualTable
        columns={visibleColumns}
        disablePaddingStart={true}
        onSort={onSort}
        RowComponentProps={({ row }) => {
          return {
            hover: false,
            className: classNames({
              [styledVirtualTableClasses.missingData]: missingDataAssetIds.includes(row.id),
              [styledVirtualTableClasses.targetAsset]: row.type === BenchmarkingItemType.Target,
            }),
          }
        }}
        rows={assets}
        sort={sort}
        tableEmptyProps={{
          message: 'Please change your search query',
          title: 'No companies',
        }}
        variant={'inline'}
      />

      <AddAssetsDialog
        alreadyAddedAssetIds={assets.map((asset) => asset.id)}
        buttonText={'Add companies'}
        onClose={() => setOpenAddAssetsDialog(false)}
        onSave={onAddAssets}
        open={openAddAssetsDialog}
        title={'Add companies to the benchmark'}
      />

      <BookmarksListCreateDialog
        assetIdsToAdd={assets
          .map((asset) => asset.id)
          .filter((assetId) => !deselectedAssetIds.includes(assetId))}
        dialogTitle={'Save selection as Bookmark'}
        infoSlot={
          <Alert
            icon={false}
            severity={'info'}>
            <Typography variant={'body2'}>
              All companies that are toggled to "Include" will be added to this bookmark list.
            </Typography>
          </Alert>
        }
        initialListTitle={`${contextName} - Benchmark`}
        onClose={() => setCreateBookmarkDialogOpen(false)}
        open={createBookmarkDialogOpen}
        disableRedirect
      />
    </StyledRoot>
  )
}
