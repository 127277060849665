import { HighlightIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import Chip from '@mui/material/Chip'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Switch, { switchClasses } from '@mui/material/Switch'
import { useMemo } from 'react'

import ConditionalWrapper from '../../common/conditional-wrapper'
import {
  createVirtualTableColumns,
  VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH,
} from '../../common/virtual-table'
import AssetContentLink from '../asset/asset-content-link'
import AssetFte from '../asset/asset-fte'
import { PercentageTableCell } from '../asset/asset-list-item-table/table-cell/percentage-table-cell'
import FinancialValue from '../financial/financial-value'
import { BenchmarkingItem, BenchmarkingItemType } from './utils'

export const SPOTLIGHT_ICON_BUTTON_CLASSNAME = 'spotlight-icon-button'

const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'isMissingData',
})<{ isMissingData: boolean }>(({ theme, isMissingData }) => ({
  // Ensure enough specificity to override the background color
  [`& .${switchClasses.checked} + .${switchClasses.track}`]: isMissingData && {
    backgroundColor: theme.palette.grey[300],
  },
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

// Add the StyledHighlightIconButton
const StyledHighlightIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  color: active ? theme.palette.text.secondary : theme.palette.grey[300],
  '&:hover': {
    color: theme.palette.text.secondary,
  },
  [`&.${iconButtonClasses.disabled}`]: {
    color: theme.palette.grey[300],
  },
}))

export default function useBenchmarkingColumns(
  deselectedAssetIds: number[],
  missingDataAssetIds: number[],
  spotLightAssetIds: number[],
  onSelectAssetId: (assetId: number, isChecked: boolean) => void,
  onClickSpotlightAssetId: (assetId: number) => void,
  showRankColumn: boolean
) {
  return useMemo(() => {
    return createVirtualTableColumns<BenchmarkingItem>(
      showRankColumn && {
        headerName: 'Rank',
        field: 'relevanceRank',
        width: 90,
        sortable: true,
        defaultSortDirection: 'asc',
        align: 'right',
        renderCell: ({ row }) => {
          if (row.type === BenchmarkingItemType.Target) {
            return null // Do not show chip for target asset
          }

          if (row.type === BenchmarkingItemType.Curated) {
            return <StyledChip label={'Curated'} />
          }

          if (row.type === BenchmarkingItemType.Added) {
            return <StyledChip label={'Added'} />
          }

          return '#' + row.relevanceRank
        },
      },
      {
        field: 'name',
        headerName: 'Company name',
        width: 320 - VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH,
        flex: 1,
        align: 'left',
        sticky: true,
        defaultSortDirection: 'asc',
        renderCell: ({ row }) => (
          <AssetContentLink
            asset={row}
            id={row.id}
            logoFileUrl={row.logoFileUrl}
            name={row.name}
          />
        ),
      },
      {
        headerName: 'Business description',
        field: 'description',
        width: 240,
        sortable: false,
      },
      {
        field: 'fte',
        headerName: 'FTEs',
        align: 'right',
        width: 144,
        renderCell: ({ row }) => (
          <AssetFte
            fte={row.fte}
            range={row.fteRange}
            year={row.fteYear}
          />
        ),
      },
      {
        field: 'revenueEur',
        headerName: 'Revenue',
        align: 'right',
        width: 130,
        renderCell: ({ value, row }) => (
          <FinancialValue
            amount={value}
            year={row.revenueYear}
          />
        ),
      },
      {
        field: 'ebitdaPctRevenue',
        headerName: 'EBITDA %',
        width: 140,
        align: 'right',
        renderCell: ({ row }) => (
          <PercentageTableCell
            percentage={row.ebitdaPctRevenue}
            years={row.ebitdaPctRevenueYears}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'id',
        headerName: 'Include',
        width: 80,
        align: 'center',
        renderCell: ({ row }) => {
          return (
            <ConditionalWrapper
              condition={missingDataAssetIds.includes(row.id)}
              wrapper={(children) => (
                <Tooltip
                  placement={'top'}
                  title={'Insufficient data to plot company with selected axes'}>
                  <div style={{ display: 'inline-flex' }}>{children}</div>
                </Tooltip>
              )}>
              <StyledSwitch
                checked={!deselectedAssetIds.includes(row.id)}
                color={'success'}
                isMissingData={missingDataAssetIds.includes(row.id)}
                onChange={(_, checked) => {
                  onSelectAssetId(row.id, checked)
                }}
                size={'medium'}
              />
            </ConditionalWrapper>
          )
        },
      },
      {
        field: 'id',
        width: 60,
        sortable: false, // if this is to be enabled, we cannot use field id for this column.
        cellClassName: SPOTLIGHT_ICON_BUTTON_CLASSNAME,
        renderCell: ({ row }) => {
          const isDisabled = missingDataAssetIds.includes(row.id)
          const isActive = spotLightAssetIds.includes(row.id)

          return (
            <ConditionalWrapper
              condition={!isDisabled}
              wrapper={(children) => (
                <Tooltip
                  placement={'top'}
                  title={'Highlight in the chart'}>
                  <div style={{ display: 'inline-flex' }}>{children}</div>
                </Tooltip>
              )}>
              <StyledHighlightIconButton
                active={isActive}
                disabled={isDisabled}
                onClick={() => onClickSpotlightAssetId(row.id)}>
                <HighlightIcon />
              </StyledHighlightIconButton>
            </ConditionalWrapper>
          )
        },
      }
    )
  }, [
    missingDataAssetIds,
    onSelectAssetId,
    deselectedAssetIds,
    showRankColumn,
    spotLightAssetIds,
    onClickSpotlightAssetId,
  ])
}
